<template>
  <div>
    <transition>
      <!-- <header class="header" :style="show ? 'top:0px;' : ''"> -->
      <header class="header" :style="_isMobile() || show ? '' : 'background-color: transparent; border-bottom:0;'">
        <div class="container">
          <div id="nav-btn" @click="toggleMenu">
            <el-icon :size="36" color="#FFF">
              <fold />
            </el-icon>
          </div>
          <div class="logo">
            <el-image
              fit="cover"
              :src="require('../assets/logo.png')"
            ></el-image>
          </div>

          <div v-show="menuAcitve" class="nav">
            <ul id="main-menu">
              <li
                v-for="m in menu"
                :key="m.name"
                @mouseover="mouseover"
                @mouseleave="mouseleave"
                :class="m.isActive ? 'active' : ''"
              >
                <transition>
                  <div
                    class="bar"
                    @click="to(m.url, m.list && m.list.length)"
                    :class="m.isActive ? 'active' : ''"
                  >
                    <p>{{ m.name }}</p>
                  </div>
                </transition>
                <div class="dropdown">
                  <ul v-if="m.list && m.list.length" class="list-ul">
                    <li v-for="l in m.list" :key="l.name" class="list-li">
                      <div @click="to(l.url)">
                        {{ l.name }}
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </transition>
  </div>
</template>

<script>
// import { nextTick } from "vue";
import { Fold } from "@element-plus/icons";
export default {
  name: "nav",
  props: {
    isFlexible: Boolean,
  },
  components: {
    Fold,
  },
  data() {
    return {
      show: false,
      menuAcitve: false,
    };
  },
  computed: {
    menu: function () {
      let _menu = [
        {
          name: this.$t("menu.home"),
          url: "/",
          isActive: true,
          list: [],
        },
        {
          name: this.$t("menu.case"),
          url: "/case",
          isActive: false,
          list: [
            { name: this.$t("menu.case-changzhou"), url: "/case/changzhou" },
            // { name: this.$t("menu.case-songjiang"), url: "/case/songjiang" },
          ],
        },
        {
          name: this.$t("menu.about"),
          url: "/About",
          isActive: false,
          // list: [231231313213123213, "dfsdfa"],
        },
        { name: this.$t("menu.join"), url: "/JoinUs", isActive: false },
      ];
      this.checkMenuActive(_menu)
      return _menu
    },
  },
  created() {
    if (!this.isFlexible) this.show = true;
    this.checkMenuActive(this.menu);
    console.log(this.menu);
    // width
    if (this._isMobile()) this.menuAcitve = false;
    else this.menuAcitve = true;
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    to(url, has_children) {
      if (url == "/JoinUs") {
        let u = this._isMobile()
          ? "https://m.liepin.com/company/12207037/"
          : "https://liepin.com/company/12207037/";
        window.location.href = u;
        // window.open()
        return;
      }
      if (url && !has_children) this.$router.push(url);
    },
    scrollToTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop);
      if (this.isFlexible) {
        if (scrollTop >= 300) this.show = true;
        else this.show = false;
      }
    },
    toggleMenu() {
      this.menuAcitve = !this.menuAcitve;
    },
    mouseover(e) {
      e.currentTarget.setAttribute("class", "active opened");
      // e.currentTarget.setAttribute("class", "opened");
    },
    mouseleave(e) {
      console.log(e);
      // console.log(e.currentTarget)
      // console.log(e.currentTarget.getAttribute('class'))
      let nc = e.currentTarget
        .getAttribute("class")
        .replace("active opened", "");
      e.currentTarget.setAttribute("class", nc);
    },
    checkMenuActive(_menu) {
      // active
      let page = this.$route.path;
      // console.log(page);
      _menu.forEach((m) => {
        m.isActive = false;
        if (page == m.url) m.isActive = true;
        else if (m.list && m.list.length) {
          m.list.forEach((ml) => {
            if (page == ml.url) {
              m.isActive = true;
              // ml.isActive = true;
            }
            // else ml.isActive = false;
          });
        } else m.isActive = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$main-color: #47a6db;

.header {
  @media only screen and (max-width: 992px) {
    top: 0;
    position: fixed;
    margin: 0;
    padding: 0;
    margin-top: 0;
    height: auto;
  }
  //   @media only screen and (max-width: 992px) {
  //     top: 0;
  //     position: static;
  //     margin: 0;
  //     padding: 0;
  //     margin-top: 0;
  //     // height: 200px;
  //   }
  // top: -100px;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  position: fixed;
  background: #222327;
  box-shadow: 0px 0px 10px rgba(50, 50, 50, 0.2);
  border-bottom: solid 1px #2b2b2b;
  opacity: 0.9;
  transition: background-color 0.3s ease-in;
  height: auto;
  
  //   display: block !important;

  .container {
    @media only screen and (max-width: 767px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (min-width: 768px) {
      width: 750px;
    }
    @media (min-width: 992px) {
      width: 970px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
    // display: table;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    .logo {
      //   display: table-cell;
      //   vertical-align: middle;
      display: flex;
      align-items: center;
      float: left;
      height: 70px;
      .el-image {
        @media only screen and (max-width: 992px) {
          // width: 100px;
          // height: 10px;
        }
        height: 24px;
      }
    }
    #nav-btn {
      @media only screen and (max-width: 992px) {
        display: block;
      }
      display: none;
      float: right;
      width: 36px;
      height: 36px;
      margin-top: 11px;
      background: #333;
      padding: 6px;
      text-align: center;
      cursor: pointer;
    }

    .nav {
      ul {
        margin-top: 0;
        // margin-bottom: 10px;
        // list-style-type: disc;
        // margin-block-start: 1em;
        // margin-block-end: 1em;
        // margin-inline-start: 0px;
        // margin-inline-end: 0px;
        // padding-inline-start: 40px;
      }
      #main-menu {
        @media only screen and (max-width: 992px) {
          display: block;
          float: none;
          z-index: 200;
          width: 100%;
          margin: 0;
          padding: 0;
        }
        cursor: pointer;
        letter-spacing: 0px;
        float: right;
        height: 60px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        li {
          @media only screen and (max-width: 992px) {
            border-bottom: solid 1px #333;
            margin: 0;
            width: 100%;
            display: block;
            letter-spacing: 3px;
          }

          margin: 0px 0px;
          padding: 0px 0px;
          float: left;
          display: inline;
          list-style: none;
          position: relative;
          transition: all 0.5s;
          .active {
            background-color: $main-color;
          }
        }
        li.active {
          background-color: $main-color;
        }
        li.opened {
          .dropdown {
            display: block;
          }
        }
        .bar {
          // padding-top: 17px;
          // padding-bottom: 18px;
          display: block;
          // padding: 17px 30px 18px 30px;
          padding: 17px 0 18px;
          line-height: 35px;
          letter-spacing: 3px;
          text-decoration: none;
          color: #fff;
          text-align: center;
          outline: none;
          transition: 0.5s;
          @media (min-width: 300px) and (max-width: 979px) {
            display: block;
            width: 100%;
          }
          @media only screen and (max-width: 992px) {
            text-align: left;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
          }
          p {
            margin-left: 30px;
            margin-right: 30px;
          }
        }
        .bar:hover {
          background-color: $main-color;
        }
        .dropdown {
          @media only screen and (max-width: 992px) {
            // display: block;
            position: relative;
          }
          display: none;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translate(-50%, 0);
          width: auto;

          .list-ul {
            // margin-top: 30px;

            .list-li {
              border-bottom: solid 1px #333;
              margin: 0;
              width: 100%;
              display: block;
              letter-spacing: 3px;
              background: #222327;
              color: #fff;
              transition: all 0.5s;
              // color: #000;
              div {
                padding: 17px 30px 18px 30px;
                white-space: nowrap;
                display: inline-block;
                line-height: 16px;
                height: auto;
                background: 0 0;
                text-align: center;
              }
              div.active {
                background-color: $main-color;
              }
            }
            .list-li:hover {
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
  .container::before {
    display: table;
    content: " ";
  }
  .container::after {
    display: table;
    content: " ";
    clear: both;
  }
}
</style>